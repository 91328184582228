import React from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import quicksand from "../../static/quicksand.jpg"

export default () => (
    <Layout className="h-full w-full">
        <Helmet>
            <title>daniel stewart - images</title>
            <script src="https://kit.fontawesome.com/4d67d05bdb.js" crossorigin="anonymous"></script>
        </Helmet>        
        <div className="flex flex-row justify-around items-end mb-8 md:mb-24">
            <div className="font-black text-xl">daniel stewart</div>
            <div>images 📷</div>
        </div>
        <div className="flex flex-col md:flex-row">
            <div className="mb-6 md:mb-24 md:w-1/2">
                <p className="mb-3">There will be some photos here sometime soon</p>
            </div>
            <div className="w-full"><img className="md:w-3/4 md:ml-auto" src={quicksand} /></div>
        </div>
    </Layout>
)